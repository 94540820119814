import './src/styles/global.css';

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
}) => {
    const currentPosition = getSavedScrollPosition(location)

    if (location?.pathname?.includes('knowledge-hub') || location?.pathname?.includes('news') || location?.pathname?.includes("events") || location?.pathname?.includes("internal-resources")) {
    } else {
        window.scrollTo(...(currentPosition || [0, 0]))
    }

    return false
}